import { NETWORK_IDS } from './constants';
import { ErrorMsg } from './constants/errors';

export function hasTrailingSlash(url: URL) {
  return url.pathname.substr(-1) === '/';
}

export function isValidUrl(maybeUrl: string) {
  try {
    validateUrl(maybeUrl);
  } catch (err) {
    return false;
  }
  return true;
}

/** Takes a string url and returns a URL object if it's valid.
 * Throws an error that include the string url itself to behave similarly
 * to pre-node 16.
 */
export function validateUrl(maybeUrl: string) {
  try {
    const url = new URL(maybeUrl);
    if (url.protocol === 'http:' || url.protocol === 'https:') {
      return url;
    }
    throw new Error(`${ErrorMsg.INVALID_URL}: ${maybeUrl}`);
  } catch (err: any) {
    throw new Error(`${ErrorMsg.INVALID_URL}: ${maybeUrl}`);
  }
}

export function urlToNetworkId(href: string) {
  const url = validateUrl(href);

  if (url.pathname.length < 4) {
    return undefined;
  }

  if (hasTrailingSlash(url)) {
    url.pathname = url.pathname.substring(0, url.pathname.length - 1);
  }

  // Deals with cases where a url has been pasted more than once
  if (url.pathname.match(/https?:\/\//)) {
    return undefined;
  }

  if (url.host.includes('youtube') || url.host.includes('youtu.be')) {
    return NETWORK_IDS.youtube;
  }

  if (url.host.includes('titok')) {
    return NETWORK_IDS.tiktok;
  }

  if (url.host.includes('facebook') || url.host.includes('fb.watch')) {
    return NETWORK_IDS.facebook;
  }

  if (url.host.includes('instagram')) {
    return NETWORK_IDS.instagram;
  }

  if (url.host.includes('twitter') || url.host.includes('x.com')) {
    return NETWORK_IDS.twitter;
  }

  return undefined;
}
